
import img1 from '../../img/partner/logo/1.png';
import img2 from '../../img/partner/logo/2.png';
import img3 from '../../img/partner/logo/3.png';
import img4 from '../../img/partner/logo/4.png';
import img5 from '../../img/partner/logo/5.png';
import img6 from '../../img/partner/logo/6.png';

const TeamData = [
    {
        id:1,
        img:img1,
    },{
        id:2,
        img:img2,
    },{
        id:3,
        img:img3,
    },{
        id:4,
        img:img4,
    },{
        id:5,
        img:img5,
    },{
        id:6,
        img:img6,
    },
]

export default TeamData;