
import './OurTeam.css';
import TeamData from './TeamData.js'; 
import { useEffect, useState} from 'react'; 
import { useInView  } from 'react-intersection-observer'; 
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css'; 
import gsap,{Power1,Back} from 'gsap';

const OurTeam = () =>{
 
    const { ref: teamSection, inView: teamSectionVisible } = useInView();

    useEffect(()=>{

        if(teamSectionVisible === true){
            if(ClickedNext===true && ClickedPrev === false){
                gsap.fromTo('.slide-inner',{scaleX:0.28 ,scaleY:0.30,zIndex:9},{scale:1,zIndex:9,duration:1,delay:0.3,ease:'ease'});
                gsap.fromTo('.slide-inner',{x:375,y:110},{x:0,y:0,duration:1,delay:0.3,ease:Power1});
                gsap.fromTo('.sub-slide',{x:250},{x:0,stagger:0.2,duration:0.7,delay:0.9,ease:Power1})
                gsap.fromTo('.slide-inner .info',{x:'-100%'},{x:0,duration:0.2,delay:1.5})
                gsap.fromTo('.team-info',{x:'100%',opacity:0},{x:0, opacity:1,duration:0.8,delay:0.3, ease:Back})
                gsap.fromTo('.slider-prev',{scale:1,opacity:1},{scaleX:0.28 ,scaleY:0.30, duration:0.6,delay:0, ease:'ease'})
                gsap.fromTo('.slider-prev',{opacity:1},{opacity:0, duration:0.6,delay:0, ease:'ease'})
                setClickedNext(false);
            } 
            else if(ClickedPrev === true && ClickedNext===false){
                gsap.fromTo('.first .info',{x:'0%',opacity:1},{x:'-100%',opacity:1,duration:0.4})
                gsap.fromTo('.first',{scaleX:3.55,scaleY:3.35},{scale:1,duration:0.5,delay:0.5,ease:Power1})
                gsap.fromTo('.first',{x:-375,y:-110,},{x:0,y:0,duration:1,delay:0.3,ease:Power1});
                gsap.fromTo('.slide-inner',{scaleX:0.28 ,scaleY:0.30,opacity:1, duration:0.2,delay:0, ease:'ease'},{scale:1,opacity:1})
                gsap.fromTo('.slider-prev',{opacity:0},{opacity:0})               
                gsap.fromTo('.sub-slide:not(.first)',{x:-250},{x:0,duration:0.5,stagger:0.1,ease:Power1})
                gsap.fromTo('.team-info',{x:'100%',opacity:0},{x:0, opacity:1,duration:0.8,delay:0.3, ease:Back})
                setClickedPrev(false);
            }
            else{
                // Do nothing
            }
        }
    })


    const [slideIndex,setSlideIndex] = useState(1);
    const [PreviewSlide1,setPreviewSlide1] = useState(2);
    const [PreviewSlide2,setPreviewSlide2] = useState(3);
    const [PreviewSlide3,setPreviewSlide3] = useState(4);
    const [PreviewSlide4,setPreviewSlide4] = useState(5);
    const [ClickedNext, setClickedNext] = useState(false);
    const [ClickedPrev, setClickedPrev] = useState(false);

    const NextBtn = () =>{  
        setClickedNext(true);
        setClickedPrev(false);
        if(slideIndex === TeamData.length){
            setSlideIndex(1);  
        }        
        else{
            setSlideIndex(slideIndex+1);
            setPreviewSlide1(slideIndex+2);
            setPreviewSlide2(PreviewSlide1+2);
            setPreviewSlide3(PreviewSlide1+3);
            setPreviewSlide4(PreviewSlide1+4);
        }

        //setting preview slides 
        if(PreviewSlide1 >= TeamData.length){
            setPreviewSlide1(1);
        } 
        else if(PreviewSlide1 === 1){
            setPreviewSlide1(2);
            setPreviewSlide2(3);
            setPreviewSlide3(4);
            setPreviewSlide4(5);
        }
        else{}

        if(PreviewSlide2 >= TeamData.length){
            setPreviewSlide2(1);
            setPreviewSlide3(2);
            setPreviewSlide4(3);
            setPreviewSlide1(TeamData.length);
        }else if(PreviewSlide2===1){            
            setPreviewSlide1(1);
            setPreviewSlide2(2);
            setPreviewSlide3(3);
            setPreviewSlide4(4);
        }
        else{}

        if(PreviewSlide3 >= TeamData.length){
            setPreviewSlide3(1);
            setPreviewSlide4(2);
            setPreviewSlide2(TeamData.length);
        }
        else{}

        if(PreviewSlide4 >= TeamData.length){
            setPreviewSlide4(1);
            setPreviewSlide3(TeamData.length);
        } 
        else{} 

    }

    const PrevBtn = () => { 
        setClickedNext(false);       
        setClickedPrev(true);

        if(slideIndex === 1){
            setSlideIndex(TeamData.length);
            setPreviewSlide1(1);
            setPreviewSlide2(2);
            setPreviewSlide3(3);
            setPreviewSlide4(4);
        } 
        else{
            setSlideIndex(slideIndex-1);
        }

        if(PreviewSlide1===1){
            setPreviewSlide1(TeamData.length);
            setPreviewSlide2(1);
            setPreviewSlide3(2);
            setPreviewSlide4(3);
        }
        else{
            setPreviewSlide1(PreviewSlide1-1);
        }

        if(PreviewSlide2===1){
            setPreviewSlide2(TeamData.length);
            setPreviewSlide3(1);
            setPreviewSlide4(2);
        }
        else{
            setPreviewSlide2(PreviewSlide2-1);
        }

        if(PreviewSlide3===1){
            setPreviewSlide3(TeamData.length);
            setPreviewSlide4(1);
        }
        else{
            setPreviewSlide3(PreviewSlide3-1);
        }

        if(PreviewSlide4===1){
            setPreviewSlide4(TeamData.length);
        }
        else{
            setPreviewSlide4(PreviewSlide4-1);
        }

    }

    const option={
        nav: true,
        responsive:{
            0:{
                items:1
            },
            1000:{
                items:4
            }
        }
    }

    return(
        <>
            <div className="team-section pd100" ref={teamSection}>
                <div className="container">
                   <div className="row">
                        <div className="col-lg-12 col-sm-12 col-md-12">
                            <div className='title'>
                                <h2 data-aos="slide-right">Working solo sucks. <br/> Here’s how we <span>change</span> that.</h2>
                                <span className='opaque-text'>
                                    <marquee>Creatively creative </marquee>
                                </span>
                            </div>
                            <img src={require('../../img/team-bg.png')} id="teamBg" alt=""/>
                            
                        </div>
                        <div className='col-12 col-lg-12 slider hide-on-mobile'>
                            
                            <div className='main-slider'>
                                {TeamData ? 
                                    <>
                                        {TeamData.map((obj,index)=>{
                                            if(obj.id===slideIndex){
                                                return(
                                                    <>
                                                        <div className='teamslider' key={index}>
                                                            <div className='slide-inner '>
                                                                <div className='img-container'>
                                                                    <img src={obj.img} alt={obj.name+' '+obj.title + ' at techqilla'}/>
                                                                </div>                                                                
                                                                <div className='info'>
                                                                    <h3>{obj.name}</h3>
                                                                    <h4>{obj.title}</h4>
                                                                </div>                                                                
                                                            </div>
                                                            <div className='slider-prev'>
                                                                {TeamData ? 
                                                                    <>
                                                                        {TeamData.map((obj,index)=>{
                                                                            if(slideIndex===1){
                                                                                if(obj.id===TeamData.length){
                                                                                    return(
                                                                                        <>
                                                                                            <div className='prev-slide-content' key={index}>
                                                                                                <img src={obj.img} alt=""/>    
                                                                                                <div className='info'>
                                                                                                    <h3>{obj.name}</h3>
                                                                                                    <h4>{obj.title}</h4>
                                                                                                </div>                                                                                                    
                                                                                            </div>
                                                                                        </>
                                                                                    ) 
                                                                                } 
                                                                            } 
                                                                            else{
                                                                                if(obj.id===slideIndex-1){
                                                                                    return(
                                                                                        <>
                                                                                            <div className='prev-slide-content' key={index}>
                                                                                                <img src={obj.img} alt=""/> 
                                                                                                <div className='info'>
                                                                                                    <h3>{obj.name}</h3>
                                                                                                    <h4>{obj.title}</h4>
                                                                                                </div>                                                                                                         
                                                                                            </div>
                                                                                    </>
                                                                                    ) 
                                                                                }  
                                                                            }
                                                                                                                                                        
                                                                        })}
                                                                        
                                                                    </>
                                                                    :
                                                                    <>Nothing Found</>
                                                                }  
                                                            </div>

                                                            
                                                            <div className='about-team'>
                                                                <p className="team-info">{obj.about}</p>     
                                                               
                                                                <div className='slider-list'>                                
                                                                    <div className='slider-list-slide'>
                                                                        {TeamData ? 
                                                                            <>
                                                                                {TeamData.map((obj,index)=>{
                                                                                    if(obj.id===PreviewSlide1){
                                                                                        return(
                                                                                            <>
                                                                                                <div className='sub-slide first' key={index}>
                                                                                                    <img src={obj.img} alt=""/>
                                                                                                    <div className='info'>
                                                                                                        <h3>{obj.name}</h3>
                                                                                                        <h4>{obj.title}</h4>
                                                                                                    </div> 
                                                                                                </div>
                                                                                            </>
                                                                                        ) 
                                                                                    }
                                                                                })}
                                                                                
                                                                            </>
                                                                            :
                                                                            <>Nothing Found</>
                                                                        }  
                                                                    </div>
                                                                    <div className='slider-list-slide'>
                                                                        {TeamData ? 
                                                                            <>
                                                                                {TeamData.map((obj,index)=>{
                                                                                    if(obj.id===PreviewSlide2){
                                                                                        return(
                                                                                            <>
                                                                                                <div className='sub-slide' key={index}>
                                                                                                    <img src={obj.img} alt=""/>
                                                                                                </div>
                                                                                        </>
                                                                                        ) 
                                                                                    }
                                                                                })}
                                                                                
                                                                            </>
                                                                            :
                                                                            <>Nothing Found</>
                                                                        }  
                                                                    </div>
                                                                    <div className='slider-list-slide'>
                                                                        {TeamData ? 
                                                                            <>
                                                                                {TeamData.map((obj,index)=>{
                                                                                    if(obj.id===PreviewSlide3){
                                                                                        return(
                                                                                            <>
                                                                                                <div className='sub-slide' key={index}>
                                                                                                    <img src={obj.img} alt=""/>
                                                                                                    
                                                                                                </div>
                                                                                        </>
                                                                                        ) 
                                                                                    }
                                                                                })}
                                                                                
                                                                            </>
                                                                            :
                                                                            <>Nothing Found</>
                                                                        }  
                                                                    </div> 
                                                                    <div className='slider-list-slide'>
                                                                        {TeamData ? 
                                                                            <>
                                                                                {TeamData.map((obj,index)=>{
                                                                                    if(obj.id===PreviewSlide4){
                                                                                        return(
                                                                                            <>
                                                                                                <div className='sub-slide' key={index}>
                                                                                                    <img src={obj.img} alt=""/>
                                                                                                    
                                                                                                </div>
                                                                                        </>
                                                                                        ) 
                                                                                    }
                                                                                })}
                                                                                
                                                                            </>
                                                                            :
                                                                            <>Nothing Found</>
                                                                        }  
                                                                    </div>                           
                                                                </div> 
                                                                <div className='navigation-team d-flex'>
                                                                    <div className='prevbtnteam' onClick={PrevBtn}>
                                                                        <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <circle r="31.2879" transform="matrix(-1 0 0 1 32.4367 32.0001)" stroke="black"/>
                                                                            <path d="M48.5605 32.9211H20.4582M20.4582 32.9211H15.3906C17.3869 32.3069 21.5639 30.4334 22.301 27.8535C21.8403 29.5427 20.8268 32.9211 20.4582 32.9211Z" stroke="black"/>
                                                                        </svg>
                                                                    </div>
                                                                    <div className='nextbtnteam' onClick={NextBtn}>
                                                                        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <circle cx="31.9949" cy="32.0001" r="31.2879" stroke="black"/>
                                                                            <path d="M15.8711 32.9211H43.9734M43.9734 32.9211H49.0411C47.0447 32.3069 42.8678 30.4334 42.1307 27.8535C42.5914 29.5427 43.6049 32.9211 43.9734 32.9211Z" stroke="black"/>
                                                                        </svg>
                                                                    </div>
                                                                </div>                                                   
                                                            </div>
                                                        </div>
                                                    </>
                                                ) 

                                            }
                                        })}                                    
                                    </>
                                    :
                                    <>Nothing Found</>
                                }
                            </div>
                            
                            
                        </div>


                        <div className='col-lg-12 slider hide-on-desktop'>
                            <div className='main-slider'>
                            <OwlCarousel className='owl-theme' {...option} items={4} loop margin={15} autoplay={true} dots={false} autoplaySpeed={1000} autoplayTimeout={3000}>
                                {TeamData ? 
                                    <>
                                        {TeamData.map((obj,index)=>{
                                            return(
                                                <>
                                                    <div className='teamslider' key={obj.id}>
                                                        <div className='slide-inner'>
                                                            <img src={obj.img} alt=""/>
                                                            <div className='info'>
                                                                <h3>{obj.name}</h3>
                                                                <h4>{obj.title}</h4>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className='about-team'>
                                                            <p>{obj.about}</p>
                                                            
                                                        </div>
                                                    </div>
                                                </>
                                            )                                             
                                        })}                                    
                                    </>
                                    :
                                    <>Nothing Found</>
                                }
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default OurTeam;