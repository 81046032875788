import React,{useState} from "react";
import './OurServices.css';
import Services from "../../Pages/Services/ServicesData";

const OurServices = () =>{

    const [serviceIndex,setserviceIndex]= useState(null);
    
    const openPanel = (e) =>{

        if(e.target.nextElementSibling.classList.contains("open")){
            e.target.nextElementSibling.classList.remove("open");            
            e.target.children[2].classList.remove('rotate');
        }
        else {
            const accordionTitlesWithIsOpen = document.querySelectorAll(".panel");
            accordionTitlesWithIsOpen.forEach((accordionTitleWithIsOpen) => {
              accordionTitleWithIsOpen.classList.remove("open");              
            });
            e.target.nextElementSibling.classList.add("open");
            e.target.children[2].classList.add('rotate'); 
        }
    }

    const openingA = (x)=>{ 
        setserviceIndex(x.target.id);
        window.location.href="/service/"+x.target.id.split(' ').join('-').toLowerCase()
    }

    const ServiceData = [
        {
            id:1,
            title: "Graphic & UI/UX Design",
            icon:[<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_426_1178)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.75141 5.34306C6.36089 4.95254 5.72772 4.95254 5.3372 5.34306C4.94668 5.73359 4.94668 6.36675 5.3372 6.75728L7.45852 8.8786C7.84904 9.26912 8.48221 9.26912 8.87273 8.8786C9.26326 8.48807 9.26326 7.85491 8.87273 7.46438L6.75141 5.34306ZM11.7011 10.2928C11.3106 9.90229 10.6774 9.90229 10.2869 10.2928C9.89642 10.6833 9.89642 11.3165 10.2869 11.707L19.4793 20.8994C19.8698 21.2899 20.503 21.2899 20.8935 20.8994C21.284 20.5089 21.284 19.8757 20.8935 19.4852L11.7011 10.2928ZM16.6509 5.34306C17.0414 5.73359 17.0414 6.36675 16.6509 6.75728L14.5295 8.8786C14.139 9.26912 13.5059 9.26912 13.1153 8.8786C12.7248 8.48807 12.7248 7.85491 13.1153 7.46438L15.2367 5.34306C15.6272 4.95254 16.2603 4.95254 16.6509 5.34306ZM8.87273 14.5355C9.26326 14.1449 9.26326 13.5118 8.87273 13.1212C8.48221 12.7307 7.84904 12.7307 7.45852 13.1212L5.3372 15.2426C4.94668 15.6331 4.94668 16.2662 5.3372 16.6568C5.72772 17.0473 6.36089 17.0473 6.75141 16.6568L8.87273 14.5355ZM18.994 10.9999C18.994 11.5522 18.5462 11.9999 17.994 11.9999H14.994C14.4417 11.9999 13.994 11.5522 13.994 10.9999C13.994 10.4476 14.4417 9.99992 14.994 9.99992H17.994C18.5462 9.99992 18.994 10.4476 18.994 10.9999ZM6.99414 11.9999C7.54642 11.9999 7.99414 11.5522 7.99414 10.9999C7.99414 10.4476 7.54642 9.99992 6.99414 9.99992H3.99414C3.44185 9.99992 2.99414 10.4476 2.99414 10.9999C2.99414 11.5522 3.44185 11.9999 3.99414 11.9999H6.99414ZM10.9941 18.9999C10.4417 18.9999 9.99405 18.5521 9.99405 17.9999V14.9999C9.99405 14.4476 10.4417 13.9999 10.9941 13.9999C11.5463 13.9999 11.9941 14.4476 11.9941 14.9999V17.9999C11.9941 18.5521 11.5463 18.9999 10.9941 18.9999ZM9.99405 7C9.99405 7.55228 10.4417 8 10.9941 8C11.5463 8 11.9941 7.55228 11.9941 7V4C11.9941 3.44772 11.5463 3 10.9941 3C10.4417 3 9.99405 3.44772 9.99405 4V7Z" fill="#FFD600"/>
            </g>
            <defs>
            <clipPath id="clip0_426_1178">
            <rect width="24" height="24" fill="white"/>
            </clipPath>
            </defs>
            </svg>
            ],
            intro:"A compelling brand communicates the character, voice, and persona you want the audience to associate with you. For us, design is way more than just a skill. It’s mostly how creativity talks and moreover, what it represents.",
            banner:require('../../img/services/design-banner.png'),
            child_services:[
                {
                    id:1,
                    sub_head:'Logo Design',
                    para:'Logo designs that communicate your brand, and represent it’s identity. ',
                    icon: require('../../img/services/icon/design/1.png')
                },
                {
                    id:2,
                    sub_head:'Brochures & Flyers',
                    para:'Effective brochures that help capture the attention of your potential customers. ',
                    icon: require('../../img/services/icon/design/2.png')
                },
                {
                    id:3,
                    sub_head:'Explainer videos',
                    para:'Interactive explainer videos that let the customer know what your product is and why it’s the best. ',
                    icon: require('../../img/services/icon/design/3.png')
                },
                {
                    id:4,
                    sub_head:'UI design',
                    para:'Engaging and clean UI designs to create seamless experiences for your prospects. ',
                    icon: require('../../img/services/icon/design/4.png')
                },
                {
                    id:5,
                    sub_head:'Print Designs',
                    para:'Print it on billboards, a t-shirt, a mug, or whatever else you want.',
                    icon: require('../../img/services/icon/design/5.png')
                },
                {
                    id:6,
                    sub_head:'Business Card Design',
                    para:'Used to communicate the quality of a brand, business cards are important to differentiate from your competition.',
                    icon: require('../../img/services/icon/design/6.png')
                },
                {
                    id:7,
                    sub_head:'Branding',
                    para:'Branding activities help create a loyal customer base and further brand recall to induce sales. ',
                    icon: require('../../img/services/icon/design/7.png')
                },
                {
                    id:8,
                    sub_head:'Animated videos',
                    para:'Creative animated videos that don’t just talk to the audience, but really connect with them. ',
                    icon: require('../../img/services/icon/design/8.png')
                },
                {
                    id:9,
                    sub_head:'Company Profile',
                    para:'Clean and sophisticated company profiles to put you in front your prospects, and help communicate your company better.',
                    icon: require('../../img/services/icon/design/9.png')
                }
            ]
    
        },
        {
            id:2,
            title: "Digital Marketing",
            icon:[<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.86607 7.00638H8.12819C7.29866 7.00638 6.47502 7.15311 5.69169 7.44046C4.32455 7.94197 3.32964 9.1935 3.10172 10.6985L3.0942 10.7481C2.9686 11.5775 2.9686 12.4225 3.0942 13.2519L3.10172 13.3015C3.32964 14.8065 4.32455 16.058 5.69169 16.5595C6.47502 16.8469 7.29866 16.9936 8.12819 16.9936H8.86607C9.31092 16.9936 9.73726 17.1811 10.0499 17.5142L10.5914 18.091C12.1879 19.7918 14.938 18.9873 15.4719 16.6633C16.176 13.5986 16.176 10.4014 15.4719 7.33668C14.938 5.01271 12.1879 4.20823 10.5914 5.90899L10.0499 6.48581C9.73726 6.81891 9.31092 7.00638 8.86607 7.00638Z" stroke="#FFD600" stroke-width="1.5"/>
            <path d="M19 7C20.7934 7.99804 22 9.86346 22 12C22 14.1365 20.7934 16.002 19 17" stroke="#FFD600" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18.5 10C19.0978 10.3992 19.5 11.1454 19.5 12C19.5 12.8546 19.0978 13.6008 18.5 14" stroke="#FFD600" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            ],
            intro:"It‘s not enough to tell your brand’s story. You need to engage with the right audience and meet them at the right place and time. With a focus on strong strategy using research and study, we aim at increasing brand awareness, interest, and desire ultimately compelling them to take action. ",
            banner:require('../../img/services/marketing-banner.png'),
            child_services:[
                {
                    id:1,
                    sub_head:'Social Media Marketing',
                    para:'Whether it’s Instagram, Facebook, Twitter, LinkedIn, Telegram or YouTube, social media marketing is needed to attract customers and increase your market reach. ',
                    icon: require('../../img/services/icon/marketing/1.png')
                
                },
                {
                    id:2,
                    sub_head:'Paid Marketing',
                    para:'Google and Facebook ads that help you get in front of a larger audience, to talk about your company and services and convert them into leads and sales.  ',
                    icon: require('../../img/services/icon/marketing/2.png')
                },
                {
                    id:3,
                    sub_head:'Search Engine Optimization',
                    para:'With people relying on Google for everything, SEO helps your company rank better and be visible to the audience who might be looking for your services/ product. ',
                    icon: require('../../img/services/icon/marketing/3.png')
                },
                {
                    id:4,
                    sub_head:'Amazon Marketing ',
                    para:'By marketing your company on Amazon, you reach out to more people, thus leading to more sales and revenue growth. ',
                    icon: require('../../img/services/icon/marketing/4.png')
                },
                {
                    id:5,
                    sub_head:'Influencer Marketing',
                    para:'As a mode to reinforce brand reputation and foster credibility, it helps to amplify your brand and gain the trust of your audience. ',
                    icon: require('../../img/services/icon/marketing/5.png')
                },
                {
                    id:6,
                    sub_head:'Network/Referral Marketing',
                    para:'By tapping into a network of people, and making use of the most important form of marketing that is word of mouth, network marketing helps increase organic growth.',
                    icon: require('../../img/services/icon/marketing/6.png')
                }
            ]
        },
        {        
            id:3,
            title: "Mobile app Development",
            icon:[<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_426_1166)">
            <path d="M17 2C18.0544 2 18.9182 2.81588 18.9945 3.85074L19 4V20C19 21.0544 18.1841 21.9182 17.1493 21.9945L17 22H7C5.94564 22 5.08183 21.1841 5.00549 20.1493L5 20V4C5 2.94564 5.81588 2.08183 6.85074 2.00549L7 2H17ZM17 4H7V20H17V4ZM12.5 16C12.7454 16 12.9496 16.1769 12.9919 16.4101L13 16.5V17.5C13 17.7454 12.8231 17.9496 12.5899 17.9919L12.5 18H11.5C11.2546 18 11.0504 17.8231 11.0081 17.5899L11 17.5V16.5C11 16.2546 11.1769 16.0504 11.4101 16.0081L11.5 16H12.5Z" fill="#FFD600"/>
            </g>
            <defs>
            <clipPath id="clip0_426_1166">
            <rect width="24" height="24" fill="white"/>
            </clipPath>
            </defs>
            </svg>],
            intro:"Setting your website up with good functionality requires a sophisticated strategy, an obsession with the user experience, and us. We help you in building the right kind of website your business needs: whether it’s dynamic and interactive, or sophisticated and static.",
            banner:require('../../img/services/development-banner.png'),
            child_services:[
                {
                    id:1,
                    sub_head:'Web Design',
                    para:'We focus on breaking down the user journey and translating that with great design that ensure that your audience doesn’t bounce back and is hooked from the second they land on it.',
                    icon: require('../../img/services/icon/development/1.png')
                },
                {
                    id:2,
                    sub_head:'Static Website',
                    para:'Static websites that’ll leave your audience with an experience that aims at getting qualified leads and accelerating your growth.',
                    icon: require('../../img/services/icon/development/2.png')
                },
                {
                    id:3,
                    sub_head:'E-commerce Website',
                    para:'Whether it’s a bakery business or a SaaS product, the whole world is sitting online and has a website and that’s why your e-commerce business should too.',
                    icon: require('../../img/services/icon/development/3.png')
                },
                {
                    id:4,
                    sub_head:'Dynamic Website',
                    para:'Functionalities and design that go hand in hand, making the crawl experience interactive and engaging.',
                    icon: require('../../img/services/icon/development/4.png')
                },
                {
                    id:5,
                    sub_head:'Landing Pages',
                    para:'Landing pages are the central place where all of your marketing and sales efforts lead to. They need to communicate the purpose and lead to action. ',
                    icon: require('../../img/services/icon/development/5.png')
                }
            ]
        },
        {
            id:4, 
            title: "Software Development",
            icon:[<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.29701 5.2338C3.52243 4.27279 4.27279 3.52243 5.2338 3.29701V3.29701C6.06663 3.10165 6.93337 3.10165 7.7662 3.29701V3.29701C8.72721 3.52243 9.47757 4.27279 9.70299 5.2338V5.2338C9.89835 6.06663 9.89835 6.93337 9.70299 7.7662V7.7662C9.47757 8.72721 8.72721 9.47757 7.7662 9.70299V9.70299C6.93337 9.89835 6.06663 9.89835 5.2338 9.70299V9.70299C4.27279 9.47757 3.52243 8.72721 3.29701 7.7662V7.7662C3.10166 6.93337 3.10166 6.06663 3.29701 5.2338V5.2338Z" stroke="#FFD600" stroke-width="1.5"/>
            <path d="M3.29701 16.2338C3.52243 15.2728 4.27279 14.5224 5.2338 14.297V14.297C6.06663 14.1017 6.93337 14.1017 7.7662 14.297V14.297C8.72721 14.5224 9.47757 15.2728 9.70299 16.2338V16.2338C9.89835 17.0666 9.89835 17.9334 9.70299 18.7662V18.7662C9.47757 19.7272 8.72721 20.4776 7.7662 20.703V20.703C6.93337 20.8983 6.06663 20.8983 5.2338 20.703V20.703C4.27279 20.4776 3.52243 19.7272 3.29701 18.7662V18.7662C3.10166 17.9334 3.10166 17.0666 3.29701 16.2338V16.2338Z" stroke="#FFD600" stroke-width="1.5"/>
            <path d="M14.297 5.2338C14.5224 4.27279 15.2728 3.52243 16.2338 3.29701V3.29701C17.0666 3.10165 17.9334 3.10165 18.7662 3.29701V3.29701C19.7272 3.52243 20.4776 4.27279 20.703 5.2338V5.2338C20.8983 6.06663 20.8983 6.93337 20.703 7.7662V7.7662C20.4776 8.72721 19.7272 9.47757 18.7662 9.70299V9.70299C17.9334 9.89835 17.0666 9.89835 16.2338 9.70299V9.70299C15.2728 9.47757 14.5224 8.72721 14.297 7.7662V7.7662C14.1017 6.93337 14.1017 6.06663 14.297 5.2338V5.2338Z" stroke="#FFD600" stroke-width="1.5"/>
            <path d="M14.297 16.2338C14.5224 15.2728 15.2728 14.5224 16.2338 14.297V14.297C17.0666 14.1017 17.9334 14.1017 18.7662 14.297V14.297C19.7272 14.5224 20.4776 15.2728 20.703 16.2338V16.2338C20.8983 17.0666 20.8983 17.9334 20.703 18.7662V18.7662C20.4776 19.7272 19.7272 20.4776 18.7662 20.703V20.703C17.9334 20.8983 17.0666 20.8983 16.2338 20.703V20.703C15.2728 20.4776 14.5224 19.7272 14.297 18.7662V18.7662C14.1017 17.9334 14.1017 17.0666 14.297 16.2338V16.2338Z" stroke="#FFD600" stroke-width="1.5"/>
            </svg>
            
            ],
            intro:"As a mode to reinforce brand reputation and foster credibility, it helps to amplify your brand and gain the trust of your audience. ",
            banner:require('../../img/services/software-banner.png'),
            child_services:[
                {
                    id:1,
                    sub_head:'Software Development',
                    para:'Designing and deploying software to accomplish your business needs.',
                    icon: require('../../img/services/icon/software/1.png')
                },
                {
                    id:2,
                    sub_head:'Mobile Development',
                    para:'Mobile applications with clean UI/UX  that provide a customized level of development to fit with your company’s targets and goals.',
                    icon: require('../../img/services/icon/software/2.png')
                },
                {
                    id:3,
                    sub_head:'Website Application',
                    para:'Website applications that combine agile product development with the latest frameworks and tools to build scalable solutions that work for your users and your business.',
                    icon: require('../../img/services/icon/software/3.png')
                },
                {
                    id:4,
                    sub_head:'CRM',
                    para:'Helping you maintain information about and cultivate relationships with your leads.',
                    icon: require('../../img/services/icon/software/4.png')
                }
            ]
        },
        {
            id:5,
            title: "web development",
            icon:[<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_426_1172)">
            <path d="M8 3C8.55228 3 9 3.44772 9 4C9 4.51283 8.61396 4.93551 8.11662 4.99327L8 5H7C6.48717 5 6.06449 5.38604 6.00673 5.88338L6 6V10C6 10.7684 5.71115 11.4692 5.2361 12C5.66365 12.4777 5.94037 13.0931 5.99144 13.7716L6 14V18C6 18.5128 6.38604 18.9355 6.88338 18.9933L7 19H8C8.55228 19 9 19.4477 9 20C9 20.5128 8.61396 20.9355 8.11662 20.9933L8 21H7C5.40232 21 4.09634 19.7511 4.00509 18.1763L4 18V14C4 13.4872 3.61396 13.0645 3.11662 13.0067L3 13C2.44772 13 2 12.5523 2 12C2 11.4872 2.38604 11.0645 2.88338 11.0067L3 11C3.51283 11 3.93551 10.614 3.99327 10.1166L4 10V6C4 4.40232 5.24892 3.09634 6.82373 3.00509L7 3H8ZM17 3C18.5977 3 19.9037 4.24892 19.9949 5.82373L20 6V10C20 10.5128 20.386 10.9355 20.8834 10.9933L21 11C21.5523 11 22 11.4477 22 12C22 12.5128 21.614 12.9355 21.1166 12.9933L21 13C20.4872 13 20.0645 13.386 20.0067 13.8834L20 14V18C20 19.5977 18.7511 20.9037 17.1763 20.9949L17 21H16C15.4477 21 15 20.5523 15 20C15 19.4872 15.386 19.0645 15.8834 19.0067L16 19H17C17.5128 19 17.9355 18.614 17.9933 18.1166L18 18V14C18 13.2316 18.2889 12.5308 18.7639 12C18.3364 11.5223 18.0596 10.9069 18.0086 10.2284L18 10V6C18 5.48717 17.614 5.06449 17.1166 5.00673L17 5H16C15.4477 5 15 4.55228 15 4C15 3.48717 15.386 3.06449 15.8834 3.00673L16 3H17Z" fill="#FFD600"/>
            </g>
            <defs>
            <clipPath id="clip0_426_1172">
            <rect width="24" height="24" fill="white"/>
            </clipPath>
            </defs>
            </svg>
            
            ],
            intro:"The path toward creating a superior company or brand starts with robust strategies and thoughtful research insights. We consult companies and help them build a solid foundation by combining methods of lead generation, strategy building, recruiting, and LinkedIn Sales Navigator Training.",
            banner:require('../../img/services/consulting-banner.png'),
            child_services:[
                {
                    id:1,
                    sub_head:'Strategy Building',
                    para:`Raise brand awareness, generate new leads, and grow your business's revenue with strategies that perform.`,
                    icon: require('../../img/services/icon/consulting/1.png')
                },
                {
                    id:2,
                    sub_head:'Lead Generation',
                    para:'Generate good leads for your business through various methodologies and frameworks. ',
                    icon: require('../../img/services/icon/consulting/2.png')
                },
                {
                    id:3,
                    sub_head:'LinkedIn Sales Navigator Training',
                    para:'Find, contact, and close sales using LinkedIn’s sales navigator that has a lot of benefits from integrating everything from your sales tools to CRM.',
                    icon: require('../../img/services/icon/consulting/3.png')
                },
                {
                    id:4,
                    sub_head:' Recruiting ',
                    para:'Attract the top talent pool for your company with comprehensive recruiting methods.',
                    icon: require('../../img/services/icon/consulting/4.png')
                }
            ]
        },
        {
            id:6,
            title: "Content Creation",
            icon:[<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_426_1158)">
            <path d="M16.0354 3.01467C17.16 1.88995 18.9556 1.84497 20.134 2.87971L20.278 3.01467L20.9851 3.72177C22.1098 4.84649 22.1548 6.64205 21.1201 7.82042L20.9851 7.96441L9.77281 19.1767C9.61378 19.3357 9.42155 19.4564 9.21077 19.5307L9.04943 19.5776L4.59453 20.6057C3.91174 20.7632 3.29593 20.1918 3.37445 19.5184L3.39408 19.4053L4.42213 14.9503C4.4727 14.7312 4.57173 14.527 4.71109 14.3523L4.82306 14.227L16.0354 3.01467ZM15.3282 6.55026L6.33867 15.5398L5.70227 18.2975L8.45999 17.6611L17.4495 8.67158L15.3282 6.55026ZM18.8638 4.42888C18.5033 4.0684 17.9361 4.04067 17.5438 4.34569L17.4496 4.42888L16.7424 5.13605L18.8637 7.25737L19.5709 6.5502C19.9314 6.18972 19.9591 5.62249 19.6541 5.2302L19.5709 5.13599L18.8638 4.42888Z" fill="#FFD600"/>
            </g>
            <defs>
            <clipPath id="clip0_426_1158">
            <rect width="24" height="24" fill="white"/>
            </clipPath>
            </defs>
            </svg>
            ],
            intro:"The path toward creating a superior company or brand starts with robust strategies and thoughtful research insights. We consult companies and help them build a solid foundation by combining methods of lead generation, strategy building, recruiting, and LinkedIn Sales Navigator Training.",
            banner:require('../../img/services/consulting-banner.png'),
            child_services:[
                {
                    id:1,
                    sub_head:'Strategy Building',
                    para:`Raise brand awareness, generate new leads, and grow your business's revenue with strategies that perform.`,
                    icon: require('../../img/services/icon/consulting/1.png')
                },
            ]
        },
        {
            id:7,
            title: "Virtual assistance (VA)",
            icon:[<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.5281 12.1135H15.5249C15.3179 12.1135 15.1499 11.9455 15.1499 11.7385V7.61354C15.1499 7.40649 15.3178 7.23853 15.5249 7.23853H16.5281C17.6292 7.23853 18.5249 8.13438 18.5249 9.23539V10.1166C18.5249 11.2177 17.6292 12.1135 16.5281 12.1135V12.1135ZM15.8999 11.3635H16.5281C17.2157 11.3635 17.7749 10.8042 17.7749 10.1166V9.23536C17.7749 8.54776 17.2157 7.98849 16.5281 7.98849H15.8999V11.3635Z" fill="#FFD600"/>
            <path d="M8.3999 12.1135H7.39677C6.29575 12.1135 5.3999 11.2177 5.3999 10.1166V9.23539C5.3999 8.13438 6.29575 7.23853 7.39677 7.23853H8.3999C8.60696 7.23853 8.77492 7.40647 8.77492 7.61354V11.7385C8.77492 11.9455 8.60697 12.1135 8.3999 12.1135V12.1135ZM7.39677 7.98859C6.70917 7.98859 6.1499 8.54786 6.1499 9.23546V10.1167C6.1499 10.8043 6.70917 11.3636 7.39677 11.3636H8.02488V7.98856L7.39677 7.98859Z" fill="#FFD600"/>
            <path d="M18.232 20.7384H5.76811C5.30673 20.7384 4.87267 20.5348 4.57737 20.1799C4.28487 19.8283 4.16447 19.3697 4.24724 18.9218C4.51121 17.4933 5.16788 16.1656 6.14608 15.0821C6.98248 14.1557 8.02069 13.4439 9.17502 13.0007C8.48046 12.1625 8.09115 11.0509 8.09115 9.86519C8.09115 7.36569 9.84469 5.33228 12.0001 5.33228C14.1554 5.33228 15.909 7.36583 15.909 9.86519C15.909 11.0509 15.5198 12.1623 14.8251 13.0007C15.9794 13.4438 17.0176 14.1556 17.854 15.0821C18.8322 16.1656 19.4888 17.4932 19.7527 18.9218C19.8357 19.3697 19.7154 19.8282 19.4227 20.1799C19.1277 20.5348 18.6934 20.7384 18.232 20.7384ZM11.9999 6.08258C10.2581 6.08258 8.84104 7.77959 8.84104 9.86567C8.84104 11.0631 9.30081 12.1648 10.1025 12.8883C10.2031 12.9791 10.2469 13.1172 10.217 13.2494C10.1872 13.3815 10.0882 13.4874 9.9584 13.5261C7.41818 14.2833 5.4656 16.4549 4.98458 19.0584C4.94239 19.2866 5.004 19.5206 5.15359 19.7005C5.30614 19.8838 5.53005 19.9888 5.76793 19.9888H18.2318C18.4698 19.9888 18.6936 19.8838 18.846 19.7006C18.9956 19.5207 19.0572 19.2868 19.015 19.0585C18.5338 16.4551 16.5814 14.2836 14.0412 13.5262C13.9113 13.4875 13.8123 13.3817 13.7826 13.2495C13.7527 13.1173 13.7965 12.9792 13.8971 12.8884C14.6988 12.1649 15.1586 11.0633 15.1586 9.86581C15.1586 7.77986 13.7416 6.08258 11.9999 6.08258Z" fill="#FFD600"/>
            <path d="M15.8999 20.6856C15.6929 20.6856 15.5249 20.5177 15.5249 20.3106V16.9356C15.5249 16.7285 15.6928 16.5605 15.8999 16.5605C16.107 16.5605 16.2749 16.7285 16.2749 16.9356V20.3106C16.2749 20.5176 16.107 20.6856 15.8999 20.6856Z" fill="#FFD600"/>
            <path d="M8.02492 20.6856C7.81786 20.6856 7.6499 20.5177 7.6499 20.3106V16.9356C7.6499 16.7285 7.81785 16.5605 8.02492 16.5605C8.23199 16.5605 8.39994 16.7285 8.39994 16.9356V20.3106C8.39994 20.5176 8.23199 20.6856 8.02492 20.6856Z" fill="#FFD600"/>
            <path d="M16.6498 7.98854C16.4427 7.98854 16.2748 7.8206 16.2748 7.61353C16.2748 5.54579 14.5925 3.86335 12.5246 3.86335H11.3996C9.33188 3.86335 7.64945 5.54562 7.64945 7.61353C7.64945 7.82058 7.4815 7.98854 7.27443 7.98854C7.06736 7.98854 6.89941 7.8206 6.89941 7.61353C6.89941 5.13223 8.91812 3.11353 11.3994 3.11353H12.5244C15.0057 3.11353 17.0244 5.13223 17.0244 7.61353C17.0244 7.82058 16.8565 7.98854 16.6494 7.98854H16.6498Z" fill="#FFD600"/>
            </svg>
            
            ],
            intro:"The path toward creating a superior company or brand starts with robust strategies and thoughtful research insights. We consult companies and help them build a solid foundation by combining methods of lead generation, strategy building, recruiting, and LinkedIn Sales Navigator Training.",
            banner:require('../../img/services/consulting-banner.png'),
            child_services:[
                {
                    id:1,
                    sub_head:'Strategy Building',
                    para:`Raise brand awareness, generate new leads, and grow your business's revenue with strategies that perform.`,
                    icon: require('../../img/services/icon/consulting/1.png')
                },
            ]
        }
    ]
    
    
    return(
        <section className=" our-services">

            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="title">
                            <h3>Our <span>Services</span></h3>
                            <h4>We provide IT & Business solutions</h4>
                        </div>
                        <section className='accordion-section'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-xl-12 col-lg-12 col-sm-12 col-12'>
                                        {ServiceData.map((obj,index)=>{
                                            var subServices=obj.child_services;
                                            return(
                                                <div key={index}>
                                                    <div className='accordion'>
                                                        <button className='acc-btn' onClick={openPanel}>
                                                            0{obj.id}{obj.icon}{obj.title}
                                                            <svg className="sidearrow" width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0397 4.53145C14.6446 3.91042 17.3555 3.91041 19.9604 4.53145C23.7222 5.42831 26.6594 8.41368 27.5418 12.2371C28.1528 14.8847 28.1528 17.64 27.5418 20.2876C26.6594 24.1111 23.7222 27.0964 19.9604 27.9933C17.3555 28.6143 14.6446 28.6143 12.0397 27.9933C8.2779 27.0964 5.34066 24.1111 4.45827 20.2876C3.84724 17.6401 3.84724 14.8847 4.45827 12.2371C5.34066 8.41368 8.2779 5.42831 12.0397 4.53145ZM16.8 13.0191C16.8 12.57 16.4418 12.206 16 12.206C15.5582 12.206 15.2 12.57 15.2 13.0191V15.4492H12.809C12.3672 15.4492 12.009 15.8133 12.009 16.2623C12.009 16.7114 12.3672 17.0754 12.809 17.0754H15.2V19.5056C15.2 19.9547 15.5582 20.3187 16 20.3187C16.4418 20.3187 16.8 19.9547 16.8 19.5056V17.0754H19.191C19.6328 17.0754 19.991 16.7114 19.991 16.2623C19.991 15.8133 19.6328 15.4492 19.191 15.4492H16.8V13.0191Z" fill="white"/>
                                                            </svg>
                                                        </button>
                                                        <div className='panel'>
                                                            <div className='panel-list'>
                                                                <ul>
                                                                    {subServices ? 
                                                                        <>
                                                                            {obj.child_services.map((child_services,index)=>{
                                                                                
                                                                                if(index<5){
                                                                                    return(
                                                                                        <>                                                                                
                                                                                            <li key={index} id={obj.title} onClick={(el)=>openingA(el)}>{child_services.sub_head}</li>
                                                                                        </>
                                                                                    );
                                                                                }
                                                                            })}
                                                                        </>
                                                                        :
                                                                        <li>No Service</li>
                                                                    }
                                                                </ul>
                                                                <ul>
                                                                    {subServices ? 
                                                                        <>
                                                                            {obj.child_services.map((child_services,index)=>{
                                                                                
                                                                                if(index>=5){
                                                                                    return(
                                                                                        <>
                                                                                            <li key={index} id={obj.title} onClick={(el)=>openingA(el)}>{child_services.sub_head}</li>
                                                                                        </>
                                                                                    );
                                                                                }
                                                                            })}
                                                                        </>
                                                                        :
                                                                        null
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurServices;