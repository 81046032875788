

const SliderData = [
    {
        id:1,
        heading:[
                <div>
                    <span className='blueclr'>IT Service Agency</span> 
                    <h3>Marketing is no more a risk you have to take.</h3>
                </div>
                ],
        link:"/about",
        content:"We partner with startups and other companies that are mission-oriented and want to set new standards for sustainable growth. ",
        banner:[
            <div className='banner-1'>
                <img src={require('../../img/Frame.png')} alt=""/>
            </div>
        ]
    },{
        id:2,
        heading:[
                <div>
                <span className='blueclr'>IT Service Agency</span>
                <h3>Designing is the main part of every company</h3>
                </div>
                ],
        link:"/portfolio/digital-marketing",
        content:"We partner with startups and other companies that are mission-oriented and want to set new standards for sustainable growth. ",
        banner:[
            <div className='banner'>
                <img src={require('../../img/banner-2.png')} id="bannerimg" alt="" />
            </div>
        ]

    },{
        id:3,
        heading:[
                <div>   
                    <span className='blueclr'>IT Service Agency</span> 
                    <h3>Digital marketing is the key of bussiness growth</h3>
                </div>
                ],
        link:"/portfolio/web-design",
        content:"We partner with startups and other companies that are mission-oriented and want to set new standards for sustainable growth. ",
        banner:[
            <div className='banner-3'>
                <div className='banner-inner'>
                    <div className='banner-img'>
                        <img src={require('../../img/banner3.png')} id="slider3"  alt="" />
                    </div>
                    <div className='banner-head'>
                        <h2>TechQilla</h2>
                    </div>
                </div>
            </div>
        ]
    }
]

export default SliderData;