import './Footer.css';
import { useEffect } from 'react';
import gsap,{Power3} from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const Footer = () => {

    useEffect(()=>{

        gsap.fromTo('.iconbar',{x:0,y:0,rotate:0,},{scrollTrigger:{trigger:'.scroll-top',start:'top center',end:'bottom bottom',scrub:4},x:-100,y:220,rotate:'90deg',duration:0.7})
        gsap.fromTo('.iconbar svg',{rotate:0},{scrollTrigger:{trigger:'.scroll-top',start:'top center',end:'bottom bottom',scrub:1},rotate:'-90deg',duration:0.4,ease:Power3.easeInOut})
        gsap.fromTo('.iconbar svg path',{fill:'#fff'},{scrollTrigger:{trigger:'footer',start:'top center',end:'bottom bottom',scrub:4},fill:'#fff',duration:0.4,ease:Power3.easeIn})
    },[])

    const toTop =()=>{  
        window.scrollTo(0, 0);
    }
    
    return (  
        <>
        <div className='whatsapp-icon'>
            <a href='https://wa.me/+919990421542'>
                <img src={require('../../img/whatsapp.png')} alt="techqilla white logo"/>
            </a>
        </div>
        
        <div className="above-footer">
            <div className="col50 join-info">
                <h2>Join Our Team</h2>
                <p>Resonate with us and feel like you’re a good fit? 
                We’re always on the lookout for amazing talent! </p>
                <button>
                    <a href="/join">
                    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M32.1581 1.45929C32.1581 0.907005 31.7104 0.45929 31.1581 0.459289L22.1581 0.459288C21.6059 0.459289 21.1581 0.907003 21.1581 1.45929C21.1581 2.01158 21.6059 2.45929 22.1581 2.45929L30.1581 2.45929L30.1581 10.4593C30.1581 11.0116 30.6059 11.4593 31.1581 11.4593C31.7104 11.4593 32.1581 11.0116 32.1581 10.4593L32.1581 1.45929ZM1.66878 32.3629L31.8652 2.1664L30.451 0.752182L0.254563 30.9487L1.66878 32.3629Z" fill="white"/>
                    </svg>
                    </a>
                </button>
            </div>
            <div className="col50 contact-info">
                <h2>Contact Us</h2>
                <p>Want to talk to us? Let’s schedule a call and discuss about your business over a cup of coffee! </p>
                <button>
                    <a href="/contact">
                        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M32.1581 1.45929C32.1581 0.907005 31.7104 0.45929 31.1581 0.459289L22.1581 0.459288C21.6059 0.459289 21.1581 0.907003 21.1581 1.45929C21.1581 2.01158 21.6059 2.45929 22.1581 2.45929L30.1581 2.45929L30.1581 10.4593C30.1581 11.0116 30.6059 11.4593 31.1581 11.4593C31.7104 11.4593 32.1581 11.0116 32.1581 10.4593L32.1581 1.45929ZM1.66878 32.3629L31.8652 2.1664L30.451 0.752182L0.254563 30.9487L1.66878 32.3629Z" fill="black"/>
                        </svg>
                    </a>
                </button>
            </div>
        </div>
        <footer >
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-3 col-12 hide-on-mobile">
                        <div className="footer-logo">
                            <svg width="50" height="63" viewBox="0 0 50 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_571_368)">
                                    <path d="M0 18.0755L50 0.550293L10.644 36.2572L0 18.0755Z" fill="white"/>
                                    <path d="M18.0628 36.2573L33.8683 18.4038L25.0001 62.5503L18.0628 36.2573Z" fill="white"/>
                                    <path d="M25 62.5503L24.006 38.5287L33.8683 18.4038L25 62.5503Z" fill="white"/>
                                    <path d="M47.5254 35.2675L40.2723 12.4961L30.5861 56.4707L47.5254 35.2675Z" fill="white"/>
                                    <path d="M30.5861 56.4707L41.1783 34.9184L40.2723 12.4961L30.5861 56.4707Z" fill="white"/>
                                    <path d="M10.644 36.2572L11.8555 23.8374L50 0.550293L10.644 36.2572Z" fill="white"/>
                                    <path d="M39.3922 50.3965L47.779 61.1752L35.8822 54.5121L39.3922 50.3965Z" fill="white"/>
                                    <path d="M35.8822 54.5119L38.9832 53.7773L47.779 61.175L35.8822 54.5119Z" fill="white"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_571_368">
                                        <rect width="50" height="62" fill="white" transform="translate(0 0.550293)"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>

                        <p id="footer-company-info">A business consultancy firm, an anchor that you can rely on. Led creatively, built strategically.</p>
                    </div>
                    <div className="col-4  hide-on-desktop">
                        <div className="footer-logo">
                            <svg width="50" height="63" viewBox="0 0 50 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_571_368)">
                                    <path d="M0 18.0755L50 0.550293L10.644 36.2572L0 18.0755Z" fill="white"/>
                                    <path d="M18.0628 36.2573L33.8683 18.4038L25.0001 62.5503L18.0628 36.2573Z" fill="white"/>
                                    <path d="M25 62.5503L24.006 38.5287L33.8683 18.4038L25 62.5503Z" fill="white"/>
                                    <path d="M47.5254 35.2675L40.2723 12.4961L30.5861 56.4707L47.5254 35.2675Z" fill="white"/>
                                    <path d="M30.5861 56.4707L41.1783 34.9184L40.2723 12.4961L30.5861 56.4707Z" fill="white"/>
                                    <path d="M10.644 36.2572L11.8555 23.8374L50 0.550293L10.644 36.2572Z" fill="white"/>
                                    <path d="M39.3922 50.3965L47.779 61.1752L35.8822 54.5121L39.3922 50.3965Z" fill="white"/>
                                    <path d="M35.8822 54.5119L38.9832 53.7773L47.779 61.175L35.8822 54.5119Z" fill="white"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_571_368">
                                        <rect width="50" height="62" fill="white" transform="translate(0 0.550293)"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>

                        
                    </div>
                    <div className="col-8 hide-on-desktop">
                        <p id="footer-company-info">A business consultancy firm, an anchor that you can rely on. Led creatively, built strategically.</p>
                    </div>
                    <div className="col-lg-3 col-4">
                        <div className="quick-links">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li><a href="/about">About</a></li>
                                <li><a href="/service">Services</a></li>
                                <li><a href="/portfolio">Portfolio</a></li>
                                <li><a href="/contact">Contact</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-8">
                        <div className="footer-info">
                            <ul>
                                <li><a href="tel:++1 (509)-331-5518">+1 (509)-331-5518</a></li>
                                <li><a href="mailto:hello@techqilla.com">hello@techqilla.com</a></li>
                                <li><a href="https://goo.gl/maps/BA3DV6hMdMEfcZ13A">C-37, Sector 2, Noida, Uttar-Pradesh, India</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-2">
                        <div className="scroll-top" onClick={toTop}>
                            <img src={require('../../img/arrow-right.png')} alt=""/>
                        </div>
                    </div>
                </div>                
                <div className="copyright">
                    <div className="row">
                        <div className="col-3"/>
                        <div className="col-lg-9  col-sm-12">
                            <p>
                                ©2022<br/>
                                Copyright TechQilla All rights reserved-2022.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        </> 
    );
}
 
export default Footer;